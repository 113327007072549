import { render, staticRenderFns } from "./Popular.vue?vue&type=template&id=0f335496&scoped=true&"
import script from "./Popular.vue?vue&type=script&lang=js&"
export * from "./Popular.vue?vue&type=script&lang=js&"
import style0 from "./Popular.vue?vue&type=style&index=0&id=0f335496&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f335496",
  null
  
)

export default component.exports